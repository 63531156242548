import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import NewsItem from "../../components/newsItem";
import { GatsbyImage } from "gatsby-plugin-image";
import ContactPerson from "../../components/contactPerson";

const News = ({ data }) => {
  const DEFAULT_FILTER = "all";
  const LOADMORE_INCREMENT = 4;

  const [year, setYear] = useState(DEFAULT_FILTER);
  const [count, setCount] = useState(LOADMORE_INCREMENT);
  const [news, setNews] = useState([]);

  useEffect(() => {
    setNews(
      data.news.edges.filter(
        (post) => year === DEFAULT_FILTER || post.node.frontmatter.year === year
      )
    );
  }, [data.news.edges, year]);

  const onFilterChange = ({ target }) => {
    setYear(target.value);
    setCount(LOADMORE_INCREMENT);
  };
  const onLoadmore = () => setCount(count + LOADMORE_INCREMENT);

  return (
    <Layout lang="en" langPath="nav.news.link">
      <Seo
        title="News about Monday"
        description="Current information about Monday Consulting and our services."
        keywords="News, latest news, up-to-date, press, events, release"
      />
      <div className="parallax-translate bg-shape-square" />
      <div className="container mt-4 mb-5">
        <div className="row">
          <div className="col-12">
            <h1 className="h5 text-color-monday-green">News</h1>
            <h2 className="text-size-2 text-size-md-1">
              The latest news about our company and our services
            </h2>
          </div>
        </div>
      </div>

      <div className="container mb-4 reveal">
        <div className="row">
          <div className="col-12">
            <div className="dropdown">
              <span className="sr-only">Filter view</span>
              <select
                id="yearfilter"
                onChange={onFilterChange}
                defaultValue={0}
              >
                <option value={0} disabled hidden>
                  Filter view
                </option>
                <option value={DEFAULT_FILTER}>All</option>
                <option>2023</option>
                <option>2022</option>
                <option>2021</option>
                <option>2020</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-3">
        <div className="row news">
          {news.slice(0, count).map(({ node }) => (
            <NewsItem key={node.id} {...node.frontmatter} />
          ))}
        </div>
      </div>
      {news.length > count && (
        <div className="container mt-3 mb-6 reveal">
          <div className="row">
            <div className="col-12 text-center">
              <button
                className="btn bg-monday-skyblue text-color-white"
                onClick={onLoadmore}
              >
                More News
              </button>
            </div>
          </div>
        </div>
      )}
      <ContactPerson
        headline="How about a sneak preview?"
        text="Want to know more about our most recent activities? Simply call or mail us today!"
        contactEmail="hello@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Marcel Scheland, Chief Executive"
      >
        <GatsbyImage
          image={data.marcel.childImageSharp.gatsbyImageData}
          alt="Marcel Scheland"
        />
      </ContactPerson>
    </Layout>
  );
};

export const pageQuery = graphql`
  query indexPageAndIndexPage {
    news: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        fields: { collection: { eq: "news" }, lang: { eq: "en" } }
        frontmatter: { inactive: { ne: true } }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            teaser
            date(formatString: "DD.MM.YYYY")
            year: date(formatString: "YYYY")
            dateRaw: date
          }
        }
      }
    }
    marcel: file(relativePath: { eq: "profiles/marcel-scheland.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
      }
    }
  }
`;

export default News;
